<template>
    <div class="container">
        <AllRecipe/>

    </div>
</template>

<script>
  import AllRecipe from '../../components/recipes/Recipeall.vue';
  export default{
    components: { AllRecipe }
}
</script>