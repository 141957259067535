<template>
  <div class="container" style="min-height: 100vh">
    <div>
      <h2 class="text-center" style="font-family: CastorTwoW01-Regular; font-size: 40px;">Recipe</h2>
    </div>
    <!--Search bar -->
    <div class="wrap d-flex justify-content-center mt-3">
       <div class="search">
        <input
          type="text"
          class=" searchTerm"
          placeholder="Search Recipes"
          v-model="searchQuery"
        />  
       </div>
       <button type="submit" class="searchButton">
        <i class="fa fa-search"></i>
         </button>
      </div>
       <!--Search bar End -->
    <div class="row">
      <v-sheet
        class="mx-auto col-lg-2 col-sm-12"
        elevation="8"
        max-width="1000"
      >
        <v-slide-group
          active-class="success"
          max="1"
          show-arrows="always"
          style="background: transparent"
          class="col-lg-3 col-md-6 col-sm-12"
        >
          <template v-slot:prev="{ on, attrs }">
            <img
              src="/img/new-image/left.svg"
              class=""
              style="
                height: 48px;
                width: 48px;
                padding-right: 20px;
                margin-top: -25px;
              "
            />
          </template>
          <template v-slot:next="{ on, attrs }">
            <img
              src="/img/new-image/right.svg"
              style="
                height: 48px;
                width: 48px;
                padding-left: 20px;
                margin-left: 20px;
                margin-top: -25px;
              "
            />
          </template>
          <div v-if="categories.length < 1">
            <v-slide-item v-for="n in 2" :key="n" style="margin-top: 10px">
              <skeleton-box
                width="250px"
                height="100px"
                class="col-3 ml-3"
                style="border-radius: 10%; margin-bottom: 50px"
              />
            </v-slide-item>
          </div>
          <template v-else>
            <v-slide-item
              v-for="item in categories"
              :key="item.id"
              style="
                margin-top: 30px;
                margin-left: 25px;
                background: transparent;
                border-radius: 10%;
              "
              class="row"
            >
              <v-card
                height="135"
                width="130"
                @click="selectCategory(item)"
                style="border: none; box-shadow: none"
                class="cat-card custom-ripple"
              >
                <div
                  class="bor"
                  style="background-image: url('/img/product/2.svg')"
                >
                  <div class="gradient-inside">
                    <img class="hel px-2 py-2" :src="item.thumbnail" />
                  </div>
                </div>
                <div class="mt-2 mb-2 text-wrap category-name">
                  {{ item.name }}
                </div>
              </v-card>
            </v-slide-item>
          </template>
        </v-slide-group>
      </v-sheet>
    </div>

    <div class="text-center text-bold mt-4 ml-3 mr-3" v-if="selectedCategory">
      <hr style="" class="d-none d-lg-flex productline" />
      <h1 class="text-center sideline" style="">{{ selectedCategory.name }}</h1>
      <hr style="" class="d-none d-lg-flex productline2" />
    </div>

    <div v-if="categories.length < 1" class="row">
      <div
        v-for="n in 10"
        :key="n"
        style="margin-top: 10px"
        class="col-lg-3 col-md-4 col-sm-6"
      >
        <skeleton-box
          width="250px"
          height="200px"
          style="border-radius: 10%; margin-bottom: 50px"
        />
      </div>
    </div>
    <div v-else class="container2">
      <div v-if="filteredRecipes.length < 1" class="mt-5 mb-5">
        <h4 class="text-center">No recipe found....</h4>
      </div>
      <div v-else class="row mt-3"  style="">
        <div v-for="recipe in filteredRecipes" :key="recipe.id">
          <div v-if="recipe.is_published == 1">
            <div
              class="col-lg-3 col-md-12 col-sm-12 col-xs-12 ml-2"
              style="margin-bottom: 15px"
            >
              <div class="card-design">
                <router-link
                  class="readmore"
                  :to="{
                    name: 'singleRecipe',
                    params: { id: recipe.id },
                    query: { catId: recipe.category_id },
                  }"
                >
                  <img class="img-fluid" alt="" :src="recipe.thumbnail" />
                </router-link>
              </div>
            </div>
            <p class="text-center product-name">
              <router-link
                class="readmore"
                :to="{
                  name: 'singleRecipe',
                  params: { id: recipe.id },
                  query: { catId: recipe.category_id },
                }"
              >
                {{ recipe.title }}
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonBox from "../common_components/SkeletonBox.vue";
export default {
  components: { SkeletonBox },
  data() {
    return {
      selectedCategory: null,
      recipes: [],
      selectedColor: "red",
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters({
      categories: "category/GET_RECIPE_CATEGORIES",
    }),

    filteredRecipes() {
      let filtered = this.recipes;
      if (this.selectedCategory) {
        filtered = filtered.filter(
          (recipe) => recipe.category_id == this.selectedCategory.categories_id
        );
      }
      if (this.searchQuery !== "") {
        filtered = filtered.filter((recipe) =>
          recipe.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      return filtered;
    },
  },
  methods: {
    selectCategory(item) {
      this.selectedCategory = item;
    },
    setColor(color) {
      this.selectedColor = color;
    },
    getProductByCategoryId() {
      this.axios.get(`/recipes?category_id`).then((response) => {
        this.recipes = response.data.data;
        // console.log(this.products[0].name)
      });
    },
  },

  async mounted() {
    await this.$store.dispatch("category/ACT_RECIPE_CATEGORIES_LIST");
    this.getProductByCategoryId();
    this.loading = false;
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Quicksand", sans-serif;
  src: url("../../../public/fonts/Quicksand-VariableFont_wght.ttf");
}
@font-face {
  font-family: "CastorTwoW01-Regular";
  src: url("../../../public/fonts/Castor\ Two\ W01\ Regular.ttf");
}
* {
  margin: 0;
}
html,
body {
  height: 100% !important;
  margin: 0;
  padding: 0;
}

hr {
  height: 4px;
  background-color: #d52b05;
  border: none;
}
/* .main-sec {
  
  
   
  } */
.bor {
  border-radius: 10%;
  text-align: center;
  width: 125px;
  height: 100px;
  /* z-index: 2; */
  /* border-image: repeating-linear-gradient(30deg, #4d9f0c, #9198e5, #4d9f0c 20px) 60; */
}

.gradient-inside {
  border-radius: 10%;
  width: 125px;
  height: 100px;
}

.hel {
  object-fit: fill;
  margin: auto;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: -2px;
}
.card-design {
  margin: auto;
  width: 270px;
  height: 217px;
  border-radius: 40px;
  max-width: 324px;
  max-height: 340px;
  padding-top: 1px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 1px;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-top: 6px solid black;
  border-bottom: 7px solid black;
}
.img-fluid {
  /* background: linear-gradient(312.02deg, #CF9333 0%, #EAC348 15%, #FBF1CA 46%, #DBA939 100%); */
  border-radius: 40px;
  padding: 7px;
  display: block;
  object-fit: cover;
  margin: auto;
  height: 200px;
  width: 300px;
}

.category-name {
  text-align: center;
  font-size: 14px;
  font-family: Quicksand;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: -5px;
  width: 130px;
  color: #2f4f4f;
  z-index: 1;
}
.custom-ripple {
  position: relative;
}

.custom-ripple::after {
  content: "";
  position: absolute;
  width: 140px;
  height: 160px;
  top: -6%;
  left: -10px;
  background-color: rgba(255, 255, 0, 0.264);
  transform: scale(0);
  transition: transform 0.3s ease-out;
  pointer-events: none;
  z-index: -1;
  border-radius: 10%;
}
.custom-ripple::before {
  background: transparent;
}

.custom-ripple:focus::after,
.custom-ripple:active::after {
  transform: scale(1);
}

.product-name a {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin-top: -100px;
}
.product-name:hover a {
  color: #e54c2a;
}
.productline {
  margin-top: 0px;
  margin-right: calc(100% - 380px);
  color: blue;
}
.sideline {
  margin-top: -30px;
  font-family: CastorTwoW01-Regular;
}
.productline2 {
  margin-top: -30px;
  margin-left: calc(100% - 380px);
}
@media (min-width: 1904px) {
  .container2 {
    max-width: 100% !important;
  }
}
@media (min-width: 900px) and (max-width: 1260px) {
  .v-slide-group >>> .v-slide-group__wrapper {
    max-width: 300px !important;
  }
  .hello {
    margin-left: 200px;
  }
}

@media (min-width: 1270px) and (max-width: 1860px) {
  .container2 {
    max-width: 100%;
  }
}
@media only screen and (max-width: 769px) {
  .container2 {
    max-width: 100%;
  }
}
@media (width: 414px) {
  .cat-card {
    margin-left: 40px !important;
    width: 160px !important;
  }
}
@media (min-width: 380px) and (max-width: 767px) {
  .cat-card {
    margin-left: 40px !important;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .productline {
    margin-top: 0px;
    margin-right: calc(100% - 200px);
  }
  .productline2 {
    margin-top: -30px;
    margin-left: calc(100% - 200px);
  }
}
/* serchbar design */
.wrap{
            display: flex;
            margin-top: 10px;
            width: 100%;
            
        }
    .search {
      z-index:1;
      width: 470px;
      position: relative;
      display: flex;
      border: 2px solid #C0272D;
      border-right: none;
      border-radius: 40px 0 0 40px;
      background: #E5E5E5;
    }

    @media (max-width:700px){
        .search {
            width:50%;
        }

    }
   

    
    .searchTerm {
      width: 100%;
      border-right: none;
      padding: 15px;
      height: 20px;
      border-radius: 5px 0 0 5px;
      outline: none;
      color: #9DBFAF;
    }
    .searchTerm::placeholder{

    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    /* Black */
    color: #231F20;
    }
    .searchTerm:focus{
      color: #231F20;
      font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    }

    
    .searchButton {
      position: relative;
      width: 103px;
      height: 64px;
      background:#231F20;
      align-content: center;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      border: 2px solid #C0272D;
      border-left: none;
      border-radius: 0px 40px 40px 0px;
    }

    .searchButton i {
      font-size: 40px;
      background: -webkit-linear-gradient(90deg, #CF9333 0.16%, #EAC348 15.12%, #FBF1CA 46.03%, #DBA939 99.88%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      height: 40px;
      width: 40px;
    }

    @media (max-width:760px){
      .search {
        left:5%;
      }
        .searchButton {
            width:30%;
            right:4%;
        }
        .searchButton i {
          position: absolute;
            font-size: 30px;
            justify-content: center;
            bottom: 12%;
        }

    }
    @media (max-width:700px){
        .searchButton {
            width:100px;
        }
        .searchButton i {
          position: absolute;
            font-size: 30px;
            justify-content: center;
            bottom: 10%;
        }
      }

      @media (max-width: 420px) {
        .wrap {
          width: 350px;
        }
        .searchButton i {
          position: absolute;
            font-size: 20px;
            justify-content: center;
            bottom: 0%;
            right:5%;
        }
        .search {
          left: 8%;
        }
        .searchButton {
            left: 0%;
        }
      }
      @media (max-width:380px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          height: 100%;
        }
        .searchButton {
            width:82px;
            height:54px;
        }
        .searchButton i {
          position: absolute;
            font-size: 20px;
            justify-content: center;
            bottom: -10%;
            right:5%;
        }
      }

      @media (max-width:360px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          height: 100%;
          width: 100px !important;
        }
        .searchButton {
            width:70px;
            height:54px;
        }
        .searchButton i {
          position: absolute;
            font-size: 16px;
            justify-content: center;
            bottom: -10%;
            right:-5%;
        }
      }
      @media (max-width:350px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          height: 100%;
          width: 100px !important;
        }
        .searchButton {
            width:60px;
            height:54px;
        }
        .searchButton i {
          position: absolute;
            font-size: 10px;
            justify-content: center;
            bottom: -20%;
            right:-10%;
        }
        .wrap{
          width:fit-content;
        }
      }
      @media (max-width:340px){
        .searchTerm {
          width: 100%;
          padding: 1px;
        }
        .search {
          left: 8%;
          height: 100%;
          min-width: 230px !important;
        }
        .searchButton {
            width:60px;
            height:54px;
            left: 0%;
        }
        .searchButton i {
          position: absolute;
            font-size: 15px;
            justify-content: center;
            bottom: -16%;
            right:-7%;
        }
        
      }


</style>
